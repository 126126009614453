import React, { Component } from 'react'
import marysresume from '../assets/Mary Kang Resume.pdf'

class About extends Component {
  render() {

    if(this.props.data){
      var profilepic= "images/"+this.props.data.image;
      var bio = this.props.data.bio;
      var phone= this.props.data.phone;
      var email = this.props.data.email;
    }

    return (
      <section id="about">
      <div className="row">
         <div className="three columns">
            <img className="profile-pic"  src={profilepic} alt="Mary Kang Profile" />
         </div>
         <div className="nine columns main-col">
            <h2>About Me</h2>

            <p>{bio}</p>
            <div className="row">
               <div className="columns contact-details">
                  <h2>Contact Details</h2>
                  <p className="address">
						   {/* <span>{name}</span><br />
						   <span>{street}<br />
						   {city} {state}, {zip}
                     </span><br /> */}
                  <span>Email: {email}</span> <br/>
						<span>Phone: {phone}</span> 
					   </p>
               </div>
               <div className="columns download">
                  <p>
                  {/* <a href={resumeDownload} className="button"><i className="fa fa-download"></i>Download Resume</a> */}
                     <a href={marysresume} download className="button"><i className="fa fa-download"></i> Download Resume </a>
                  </p>
               </div>
            </div>
         </div>
      </div>

   </section>
    );
  }
}

export default About;
